<template>
  <div id="login">
    <h1 class="white--text">Connection</h1>
    <div class="d-flex justify-center">
      <v-col cols="6">
        <v-card>
          <v-card-text>
            <div class="text-center text_field">
              <v-text-field
                type="text"
                name="username"
                v-model="pseudo"
                placeholder="Username"
              ></v-text-field>
            </div>
            <div class="justify-center text_field">
              <v-text-field
                type="password"
                name="password"
                v-model="password"
                placeholder="Password"
              ></v-text-field>
            </div>
          </v-card-text>

          <v-card-actions class="justify-center">
            <v-btn
              type="button"
              v-on:click="login()"
              color="black"
              class="white--text"
            >
              Connexion</v-btn
            >
          </v-card-actions>
          {{ message }}
        </v-card>
      </v-col>
    </div>
  </div>
</template>

<script>
const axios = require("axios").default;

export default {
  name: "Login",
  data() {
    return {
      pseudo: "",
      password: "",
      message: "",
    };
  },
  methods: {
    login() {
      axios({
        method: "post",
        url: this.$store.state.url + "/login",
        data: {
          pseudo: this.pseudo,
          password: this.password,
        },
      }).then((response) => {
        if (response.data.code == 200) {
          this.$store.state.login = true;
          this.$store.state.connection = false;
          this.$store.state.user = response.data.user.name;
          this.$store.state.id = response.data.user.id;
          this.$store.state.bar_nom = response.data.user.bar_nom;

          this.$cookie.set("user", this.$store.state.user, 10);
          this.$cookie.set("login", true, 10);
          this.$cookie.set("bar_nom", this.$store.state.bar_nom, 10);
          this.$cookie.set("id", this.$store.state.id, 10);

          this.$router.push("liste-suggestion");
        } else {
          this.message = "Identifiants ou mot de passe incorrect";
        }
      });
    },
  },
  created() {
    this.$store.state.connection = true;
  },
};
</script>

<style scoped>
.text_field {
  width: 80%;
  margin: auto;
}
</style>